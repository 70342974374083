import './App.css';
import FacebookLogin from 'react-facebook-login';
import React, { useEffect, useState } from 'react';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from "react-share";

import logo from './img/logo.png'

function App() {

    useEffect(() => {
        document.title = "¿Cuánta gente iría a un concierto de Gorillaz en Lima? - Bulla.pe"
     }, []);

    const [usuario, setUsuario] = useState({
        username: "",
        user_id: "",
        email: "",
        acceptance: true
    })

    const [votado, setVotado] = useState(false)
    const [votosTotales, setVotosTotales] = useState(0)

    const [comprobarVoto, setComprobarVoto] = useState(false)

    useEffect(() => {
        const pedirVotosTotales = async () => {
            let misHeaders = new Headers()
            misHeaders.append("Authorization", "4v39078nv0574?_#9c4m0")
            let config = {
                headers: misHeaders,
                method: 'GET'
            }
            const guardarDatos = await fetch("https://badbunny-bulla.herokuapp.com/resultados", config)
            const response = await guardarDatos.json()
            if (response.success) {
                setVotosTotales(response.content)
            }
        }
        pedirVotosTotales()
    }, [])

    const responseFacebook = (response) => {
        if (response) {
            setUsuario({
                ...usuario,
                username: response.name,
                user_id: response.id,
                email: response.email,
            })
        }
    }

    const enviarDatos = async () => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': '4v39078nv0574?_#9c4m0'
        },
            method: 'POST',
            body: JSON.stringify(usuario),
        }
        let guardarDatos = await fetch("https://badbunny-bulla.herokuapp.com/votar", config)
        let respuesta = await guardarDatos.json()
        if (respuesta.success) {
            setVotado(true)
            setVotosTotales (votosTotales + 1)
        } else {
            setVotado(true)
            setComprobarVoto(true)
        }
    }

    const shareUrl = "https://gorillaz.bulla.pe"
    const shareText = "Acabo de votar por una fecha para Gorillaz en Lima ¡Vota tú también!"
    const shareHashtag = ["PeruWanstGorillaz", "GorillazEnPeru"]
    const shareTitle = shareText + " @Bulla_pe @Gorillaz_pe @MoveConcertsPE @veltracmusic"
    const shareAccounts = ["bulla_pe", "gorilaz_pe"]

    return (
        <React.Fragment>
            <div className='bybulla'>
                <span>Encuesta organizada por <a href='https://www.facebook.com/Gorillaz.pe' target="_blank"> Gorillaz.pe </a> y <a href="https://bulla.pe" target="_blank"><span class="bulla-logo"></span></a></span>
            </div>
            <main class="container">
                <img src={logo} alt='Gorillaz en Lima' />
                <section className='container'>
                    <h1 class="big-letters"><mark>¿Cuánta gente iría a un concierto de <b>Gorillaz en Lima?</b></mark></h1>
                    <div class="votes">
                        <span className='votes-counter'>{votosTotales}</span>
                        <span className='votes-people'><mark>Humanz inscritos</mark></span>
                    </div>
                </section>
                {
                    usuario.username.length === 0 ?
                        <section className='login-to-vote container'>
                            <p>Ingresa con Facebook y vota tú también</p>
                            <FacebookLogin
                                appId="521072692595846"
                                autoLoad={false}
                                disableMobileRedirect={true}
                                fields="name,email,picture"
                                callback={responseFacebook}
                            />
                        </section>
                        : votado === false ?
                        <section className='vote'>
                            <p>Hola, <span>{usuario.username}</span>.<br />¡Haz clic en el botón para sumar tu voto!</p>
                            <label htmlFor='acceptance'>
                                <input id="acceptance" type="checkbox" defaultChecked="true" onChange={() => { setUsuario({ ...usuario, acceptance: !usuario.acceptance }) }} /> Recibir lo último de la música desde Bulla.pe por correo.
                            </label>
                            <button type='button' onClick={enviarDatos}>¡Quiero a Gorillaz en Lima!</button>
                        </section>
                        : comprobarVoto ?
                        <section className='voted'>
                            <h2>Solo puedes votar una vez, crack. ¡Pero compártelo con tus amigos para conseguir un concierto de Gorillaz en Lima!</h2>
                        </section>
                        :
                        <section className='voted'>
                            <h2>¡Gracias por votar, {usuario.username}! Tómale un screenshot y cómpartelo con tus amigos para lograr un concierto de Gorillaz en Lima.</h2>
                            <p>Sigue las redes del club de fans <a href='https://www.facebook.com/Gorillaz.pe' target="_blank"><b>Gorillaz Perú</b> Perú </a> y <a href='https://www.facebook.com/Gorillaz.pe' target="_blank"><b>Bulla.pe</b></a> para enterarte de las novedades.</p>
                        </section>
                }
                <section className='share'>
                <p><b>¡No olvides compartirlo en redes!</b><br /><i>#PeruWantsGorillaz #GorillazenPeru</i></p>
                <FacebookShareButton url={shareUrl} quote={shareText} hashtags={shareHashtag}><FacebookIcon size={32} round={true} /></FacebookShareButton>
                <TwitterShareButton url={shareUrl} related={shareAccounts} hashtags={shareHashtag} title={shareTitle}><TwitterIcon size={32} round={true} /></TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={shareText}><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
                </section>
            </main>
        </React.Fragment>

    );

}

export default App;
